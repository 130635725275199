<script>/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */

import Order from "../../models/Order";


export default {
    name: "OrderStatusPicker",
    props: {
        value: Array | String,
        isSingle: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            internalValue: this.value,
            loading: false,
            options: []
        }
    },
    watch: {
        // update inner value when prop changed
        value: function () {
            this.internalValue = this.value;
        },
        internalValue: function () {
            this.$emit("input", this.internalValue);
        }
    },
    methods: {
        async fetchData() {
            this.loading = true;
            try {
                this.options = await Order.getStatuses();
            } catch (e) {
                this.$message.error("Ошибка получения статусов");
            } finally {
                this.loading = false;
            }
        }
    },
    mounted() {
        this.fetchData();
    }
}
</script>


<template>
    <el-select
            v-model="internalValue"
            :multiple="!isSingle"
            placeholder="Статусы заказа"
            clearable
            :loading="loading">
        <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
        </el-option>
    </el-select>
</template>


<style scoped>

</style>